<template>
  <div class="cover">
    <img v-if="pageId == 221" src="../assets/competition_cover.jpg" :alt="contents.page.title"/>
    <img v-else-if="pageId == 222" src="../assets/timing_cover.jpg" :alt="contents.page.title"/>
    <img v-else-if="pageId == 223" src="../assets/awards_cover.jpg" :alt="contents.page.title"/>
    <img v-else-if="pageId == 224" src="../assets/participants_cover.jpg" :alt="contents.page.title"/>
    <img v-else-if="pageId == 225" src="../assets/results_cover.jpg" :alt="contents.page.title"/>
    <img v-else src="../assets/general_cover.jpg" :alt="contents.page.title"/>
    <div class="container">
      <h1>{{contents.page.title}}</h1>
    </div>
  </div>
  
  <div v-if="pageId == 224">
    <Participants :globalData="globalData" :pageId="pageId"></Participants>
  </div>
  <div v-else-if="pageId == 225">
    <Results :globalData="globalData" :pageId="pageId"></Results>
  </div>
  <div v-else class="container sub-page-container">
    <div v-html="mainContent"></div>
  </div>
  <div  v-if="pageId == 231 || pageId == 232 || pageId == 233">
    <Career :contents="contents" :pageId="pageId"></Career>
  </div>
</template>

<script>
import Participants from './Participants.vue'
import Results from './Results.vue'
import Career from '../components/Career.vue'

export default {
  name: 'SubPage',
  props:['globalData','pageId'],
  components: {
    Participants, Results, Career
  },
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][this.pageId]
    const headData = contents.head
    let mainContent = contents.page.mainContent
    if(isLocalhost==true && mainContent!==undefined){
      mainContent = mainContent.replaceAll('/app','')
    }

    this.globalData.init.head.title = headData.title
    this.globalData.init.head.keywords = headData.keywords
    this.globalData.init.head.description = headData.description
    return{
      mainContent,
      contents,
      language
    }
  },
  watch:{
    pageId(){
      this.contents = this.globalData.contents[this.language][this.pageId]
      this.mainContent = this.contents.page.mainContent
      if(isLocalhost==true && this.mainContent!==undefined){
        this.mainContent = this.mainContent.replaceAll('/app','')
      }
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    },
    language(newValue){
      this.contents = this.globalData.contents[newValue][this.pageId]
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    }
  }
}
</script>
<style>
.cover{
  background: #06ABCE;
  padding-top:1.32rem;
  padding-bottom:1rem;
  position: relative;
  text-align: center;
  margin-bottom: 4.55rem;
}
.cover img{
  width: 100%;
  max-width: 1168px;
}
.cover .container{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.cover h1{
  color: #FFFEFE;
  text-align: center;
  z-index: 5;
  font-size: 1.63rem;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: bold;
}
.little-container{
  max-width: 30.19rem;
  margin:auto;
  margin-bottom: 3.64rem;
}
.little-container iframe{
  max-width: 100% !important;
}
.sub-page-container .without-radius{
  border-radius: 0;
  margin-bottom: 0!important;
}
.sub-page-container img{
    margin-bottom: 3.73rem;
    margin-right: .5rem;
    width: 100%;
    border-radius: 16px;
}
.sub-page-container a{
    color: #06ABCE;
}
.sub-page-container h2{
  position: relative;
  margin-bottom: 3.18rem;
}
.sub-page-container h3,.sub-page-container h4{
  position: relative;
  margin-bottom: 2.77rem;
}
.sub-page-container h2::after{
  content:"";
  position: absolute;
  border-bottom: 1px solid #06ABCE;
  width: 7rem;
  bottom: -0.5rem;
  left: 0;
}
.sub-page-container h3::after,.sub-page-container h4::after{
  content:"";
  position: absolute;
  border-bottom: 1px solid #06ABCE;
  width: 7rem;
  bottom: -0.91rem;
  left: 0;
}
.little-container span{
  color:#06ABCE;
}
.little-container img{
  width: auto;
  margin-bottom: initial;
}
.little-container .prizes{
  margin-bottom: 4.1rem;
  text-align: center;
}
.little-container .prizes p{
  font-size: 1.36rem;
  line-height: 2.18rem;
  text-align: start;
  margin-bottom: 1.5rem;
}
.little-container .prizes p span{
  font-weight: bold;
}
.blue-table{
  text-align: center;
  margin-bottom: 1.91rem;
}
.blue-table thead{
  background: #06ABCE;
  color: #FFFEFE;;
}
.blue-table tr, .blue-table tbody td, .blue-table th, .blue-table thead {
  border-style:none;
  vertical-align: middle;
}
.blue-table tbody tr:not(:last-child){
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
.yellow-banner{
  text-align: center;
  color: #FFFEFE;
  background-color: #FCB925;
  padding-top: 1.91rem;
  padding-bottom: 2.38rem;
  width: 100%;
}
.img-float-end{
  float: right;
  max-width: 40%;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}
.img-float-start{
  float: left;
  max-width: 40%;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
}
@media (max-width: 768px){
  .cover, .sub-page-container img{
    margin-bottom: 2.5rem;
  }
  .sub-page-container h3,.sub-page-container h4{
    margin-bottom:2rem;
  }
  .sub-page-container h3::after, .sub-page-container h4::after{
    bottom: -0.5rem;
  }
  .little-container .prizes{
    margin-bottom: 2rem;
  }
  .little-container .prizes p{
    font-size: 1.16rem;
    line-height: 2rem;
  }
}
</style>
