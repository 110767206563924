<template>
    <template v-if="this.final">
        <div class="container">
            <h2>Döntő</h2>
            <div class="blue-line"></div>
        </div>
        <div class="yellow-holder">
            <div class="container table-responsive final-results">
                <table class="table table-advance">
                    <thead>
                        <tr>
                            <th>Rangsor</th>
                            <th>Csapatnév</th>
                            <th>Iskola</th>
                            <th>Szimuláció</th>
                            <th>Prezentáció</th>
                            <th>Összpontszám</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="result in final" :key="result.teamName"> 
                            <td>{{result.rank}}</td>
                            <td>{{result.teamName}}</td>
                            <td>{{result.university}}</td>
                            <td>{{result.score1}}</td>
                            <td>{{result.score2}}</td>
                            <td>{{result.sumScore}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </template>
    <template v-if="this.semiFinal">
        <div class="container">
            <h2>Középdöntő</h2>
            <div class="blue-line"></div>
        </div>
        <div class="yellow-holder">
            <div class="container table-responsive general-results">
                <table class="table table-advance">
                    <thead>
                        <tr>
                            <th>Rangsor</th>
                            <th>Csapatnév</th>
                            <th>Iskola</th>
                            <th>Piackód</th>
                            <th>Eredmény</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="result in semiFinal" :key="result.teamName"> 
                            <td>{{result.rank}}</td>
                            <td>{{result.teamName}}</td>
                            <td>{{result.university}}</td>
                            <td>{{result.poolCode}}</td>
                            <td>{{result.score}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </template>
    <div class="container">
         <h2>Selejtező</h2>
         <template v-if="this.qualifier">
            <div class="btn-holder">
                <template v-for="(btn, index) in leagues" :key="btn.id">
                    <button class="btn btn-outline-primary"  :class="{ 'active': index === 0 }" v-on:click="changeLeague(btn.id, $event)">{{btn.name}}</button>
                </template>
            </div>
         </template>
    </div>
    <div class="yellow-holder">
        <div class="container table-responsive general-results">
            <table class="table table-advance" v-if="this.selectedLeague && this.qualifier">
                <thead>
                    <tr>
                        <th>Rangsor</th>
                        <th>Csapatnév</th>
                        <th>Iskola</th>
                        <th>Piackód</th>
                        <th>Eredmény</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="result in selectedLeague" :key="result.teamName">
                        <td>{{result.rank}}</td>
                        <td>{{result.teamName}}</td>
                        <td>{{result.university}}</td>
                        <td>{{result.poolCode}}</td>
                        <td>{{result.score}}</td>
                    </tr>
                </tbody>
            </table>
            <p v-else>Még nincsenek eredmények</p>
        </div>
        
    </div>
</template>

<script>
export default {
  name: 'Results',
  props:['globalData','pageId'],
  components: {
   
  },
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][this.pageId]
    const headData = contents.head

    this.globalData.init.head.title = headData.title
    this.globalData.init.head.keywords = headData.keywords
    this.globalData.init.head.description = headData.description

    this.fetchData()
    
    return{
        leagues:null,
        selectedLeague:null,
        qualifier:null,
        semiFinal:null,
        final:null
    }
  },
  watch:{
    language(){
      this.contents = this.globalData.contents[this.language][this.pageId]
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    }
  },
    methods:{
      fetchData() {
      this.globalData.loader.status = true;    
      this.$axios.get('/api/v1/results')
          .then((response) => {
            this.resultsData = response.data.data
            this.leagues = this.resultsData.leagues
            this.qualifier = this.resultsData.qualifier
            this.semiFinal = this.resultsData.semiFinal
            this.final = this.resultsData.final
            if(this.qualifier){
            this.selectedLeague = this.qualifier[Object.keys(this.qualifier)[0]]
            }
              
          })
          .catch(error => {
            console.log(error.message)
            console.error('There was an error!', error)
          })
          .then(() => {
            this.globalData.loader.status = false;
          });
    },
    changeLeague(id, event){
        document.activeElement.blur()
        let leagueButtons = document.querySelectorAll('.btn-outline-primary')
        for(let i=0; i<leagueButtons.length; i++){
            if(leagueButtons[i].classList.contains('active')){
                leagueButtons[i].classList.remove('active')
            }
        }
        event.target.classList.add('active')
        this.selectedLeague = this.qualifier[id]
    }
  }
}
</script>
<style scoped>
.yellow-holder{
    background-color: #FCB925;
    padding-top: 2rem;
    padding-bottom:1.14rem;
    margin-bottom: 4.55rem;
}
thead{
    background: rgba(255, 254, 254, 0.2);
    border-bottom: 2px solid rgba(255, 254, 254, 0.2);
}
th{
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: .82rem;
    line-height: 2.18rem;
    color: #000000;
    text-align: center;
}
tbody tr:first-child td{
    padding-top:1.82rem;
}
table th:nth-child(2){
    text-align: start;
}
table th:nth-child(3){
    text-align: start;
    padding-left: 2.32rem;
}
.general-results table td:nth-child(1),.general-results table td:nth-child(4),.general-results table td:nth-child(5){
    text-align: center;
}
.general-results table td:nth-child(1),.general-results table td:nth-child(2),.general-results table td:nth-child(5){
    color:#FFFEFE;
    font-weight: bold;
}
.final-results table td:nth-child(1),.final-results table td:nth-child(4),.final-results table td:nth-child(5),.final-results table td:nth-child(6){
  text-align: center;
}
.final-results table td:nth-child(1),.final-results table td:nth-child(2),.final-results table td:nth-child(6){
  color:#FFFEFE;
  font-weight: bold;
}
.table>:not(caption)>*>*{
    border: none;
}
.btn{
    margin: 0 .325rem 1.45rem .325rem;
    /* margin-bottom: 1.45rem; */
}
.btn-holder{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
@media (max-width: 767px){
    .btn{
            margin: 0 .2rem 1rem .2rem;

    }
    
}
</style>
