<template>
 <div class="yellow-banner">
     <div class="container">
        <h3>{{careerContent.text1}}</h3>
        <a class="btn btn-primary" :href="careerContent.href" target="_blank" rel="noopener" v-html="careerContent.text2"></a>
      </div>
</div>
</template>

<script>

export default{
  name:'Career',
  props:['contents','pageId'],
  computed:{
    careerContent(){
      return this.contents.career
    }
  }
}
</script>

<style scoped>
.yellow-banner{
  text-align: center;
  color: #FFFEFE;
  background-color: #FCB925;
  padding-top: 1.91rem;
  padding-bottom: 2.38rem;
  width: 100%;
  margin-bottom: 2.77rem;
}
</style>